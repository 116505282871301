import { Dispatch } from 'redux';
import { GET_OTHER_USER } from './action-types';
import { tUserAction } from './types';
import { apiClient } from '../../api';
import { updateProfileImage } from '../user/reducer.actions';
import { tInfiniteScroll } from 'src/types';

export const changeOtherUserData = (data: tUserAction) => {
    return {
        type: GET_OTHER_USER,
        payload: {
            data,
        },
    };
};

export const getOtherUserAsync = (query: tInfiniteScroll) => async (dispatch: Dispatch) => {
    let queryString = '';
    Object.keys(query).map(objKey => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        queryString += query[objKey] ? `${objKey}=${query[objKey]}&` : '';
    });

    try {
        const { data } = await apiClient.get(`/profile?${queryString}`);

        const response = data.response;
        response.profile = updateProfileImage(response.profile);

        dispatch(changeOtherUserData({ ...response, page: query.page }));
    } catch (e) {
        console.log(e);
    }
};
