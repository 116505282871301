import { IAction } from '../../interfaces';
import { initialState } from './initialState';
import { GET_OTHER_USER } from './action-types';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const userPersistConfig = {
    key: 'userState',
    storage: storage,
    whitelist: [],
};

const otherUser = (state = initialState, { payload, type }: IAction<any>) => {
    switch (type) {
        case GET_OTHER_USER: {
            return {
                ...state,
                user: payload.data.profile,
                estates: {
                    ...payload.data.estates,
                    data:
                        payload?.data.page === 1
                            ? payload?.data.estates.data
                            : [...state.estates.data, ...(payload?.data.estates.data || [])],
                },
            };
        }

        default:
            return state;
    }
};

export const otherUserReducer = persistReducer<any>(userPersistConfig, otherUser);
