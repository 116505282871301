import { tUser } from 'src/types';
import defaultUser from 'src/assets/images/defaultUser.png';
import { useState } from 'react';

import './Comments.scss';
import { MainButton } from 'src/components';

import { MentionsInput, Mention } from 'react-mentions';
import defaultMentionStyle from './defaultMentionStyle';
import defaultStyle from './defaultStyle';
import { getUsersForMention } from 'src/api';

export const WriteComment = ({ user, onSave }: { user?: tUser; onSave: (val: string) => void }) => {
    const [value, setValue] = useState('');
    const [readyText, setReadyText] = useState<string>('');
    const maxLength = 10000;
    const onChange = (e: any) => {
        setValue(e.target.value);
        readyMess(e.target.value);
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const fetchUsers = async (query, callback) => {
        const users = await getUsersForMention(query);
        callback(users.data.response);
    };

    const readyMess = (val: string) => {
        let string = val;
        const regex = /[@]*\[\w+\s+\w+(?=\])+\]+\(\w+\)/;
        const regexName = val.match(/[@]*\[\w+\s+\w+(?=\])+\]/);
        const regexId = val.match(regex);
        const ID = regexId ? regexId[0].slice(regexId[0].indexOf('(') + 1, -1) : null;
        const NAME = regexName ? regexName[0].slice(2, -1) : '';

        string = val.replace(regex, `<a href='/user?id=${ID}'>@${NAME}</a>`);

        if (string.match(regex)) {
            readyMess(string);
        } else {
            setReadyText(string);
        }
    };

    return (
        <div className="comment">
            <div
                className="comment__pic"
                style={{
                    backgroundImage: `url(${
                        user?.profile_picture ? user.profile_picture : defaultUser
                    })`,
                }}
            />
            <div className="comment__input">
                <MentionsInput
                    style={defaultStyle}
                    value={value}
                    onChange={onChange}
                    placeholder={"Mention people using '@'"}
                >
                    <Mention trigger="@" style={defaultMentionStyle} data={fetchUsers} />
                </MentionsInput>
                <div className="comment__input_footer">
                    {readyText.length > maxLength && (
                        <p className="error">The message is too long</p>
                    )}
                    <MainButton
                        buttonType="primary"
                        buttonSize="l"
                        disabled={!value || readyText.length > maxLength}
                        onClick={() => {
                            if (readyText.length <= maxLength) {
                                onSave(readyText);
                                setValue('');
                            }
                        }}
                    >
                        Comment
                    </MainButton>
                </div>
            </div>
        </div>
    );
};
