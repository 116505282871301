import { FC } from 'react';

interface IProps {
    color?: string;
}

export const IconShape: FC<IProps> = ({ color = '#6A5ECC' }) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M28.5493 2.66669C28.9825 2.66669 29.3337 3.01784 29.3337 3.451C29.3337 3.83604 29.0562 4.15627 28.6903 4.22268L28.5493 4.23531H23.0592V12.0785H28.5493C28.9825 12.0785 29.3337 12.4296 29.3337 12.8628C29.3337 13.2478 29.0562 13.568 28.6903 13.6344L28.5493 13.6471H23.0592V21.4902H28.5493C28.9825 21.4902 29.3337 21.8414 29.3337 22.2745C29.3337 22.6596 29.0562 22.9798 28.6903 23.0462L28.5493 23.0588H23.0592V28.549C23.0592 28.9822 22.708 29.3334 22.2748 29.3334C21.8898 29.3334 21.5696 29.0559 21.5032 28.69L21.4905 28.549V23.0588H13.6474V28.549C13.6474 28.9822 13.2962 29.3334 12.8631 29.3334C12.478 29.3334 12.1578 29.0559 12.0914 28.69L12.0788 28.549V23.0588H4.23562V28.549C4.23562 28.9341 3.95817 29.2543 3.59229 29.3207L3.45131 29.3334C3.06627 29.3334 2.74604 29.0559 2.67963 28.69L2.66699 28.549V3.451C2.66699 3.06597 2.94444 2.74573 3.31032 2.67932L3.45131 2.66669H28.5493ZM21.4905 21.4902V13.6471H13.6474V21.4902H21.4905ZM12.0788 13.6471H4.23562V21.4902H12.0788V13.6471ZM21.4905 12.0785V4.23531H13.6474V12.0785H21.4905ZM12.0788 4.23531H4.23562V12.0785H12.0788V4.23531Z"
                fill={color}
            />
        </svg>
    );
};
