import { FC } from 'react';

interface IProps {
    color?: string;
}

export const IconClose: FC<IProps> = ({ color = '#616161' }) => {
    return (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.68371 19.0624C5.54849 19.0624 5.4163 19.0223 5.30387 18.9472C5.19143 18.8721 5.10379 18.7653 5.05204 18.6404C5.00029 18.5155 4.98676 18.378 5.01314 18.2454C5.03953 18.1128 5.10465 17.991 5.20028 17.8954L17.8329 5.26274C17.9611 5.13453 18.135 5.0625 18.3163 5.0625C18.4976 5.0625 18.6715 5.13453 18.7998 5.26274C18.928 5.39096 19 5.56485 19 5.74617C19 5.92749 18.928 6.10139 18.7998 6.2296L6.16714 18.8622C6.10371 18.9258 6.02836 18.9762 5.94539 19.0105C5.86243 19.0449 5.7735 19.0625 5.68371 19.0624Z"
                fill={color}
                stroke={color}
            />
            <path
                d="M18.3163 19.0624C18.2265 19.0625 18.1376 19.0449 18.0546 19.0105C17.9716 18.9762 17.8963 18.9258 17.8329 18.8622L5.20024 6.2296C5.07203 6.10139 5 5.92749 5 5.74617C5 5.56485 5.07203 5.39096 5.20024 5.26274C5.32846 5.13453 5.50235 5.0625 5.68367 5.0625C5.86499 5.0625 6.03889 5.13453 6.1671 5.26274L18.7997 17.8954C18.8953 17.991 18.9605 18.1128 18.9869 18.2454C19.0132 18.378 18.9997 18.5155 18.948 18.6404C18.8962 18.7653 18.8086 18.8721 18.6961 18.9472C18.5837 19.0223 18.4515 19.0624 18.3163 19.0624Z"
                fill={color}
                stroke={color}
            />
        </svg>
    );
};
