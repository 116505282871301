export const IconToken = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M29.6205 21.6844L29.6205 21.6843C29.7836 21.2926 29.5985 20.8425 29.2072 20.6791L29.2069 20.679C28.8136 20.516 28.3651 20.701 28.2015 21.0923L28.2015 21.0924C26.1408 26.0305 21.3515 29.2213 16 29.2213C8.70983 29.2213 2.77885 23.2901 2.77885 16.0001C2.77885 8.71004 8.70983 2.77888 16 2.77888C23.2902 2.77888 29.2212 8.7101 29.2212 16.0001C29.2212 16.6792 29.1696 17.3545 29.0676 18.0072L29.0676 18.0073C29.0026 18.4265 29.2892 18.8196 29.7086 18.8849L29.7087 18.8849C30.1265 18.9497 30.5207 18.6636 30.5867 18.2441L30.5867 18.244C30.7007 17.5131 30.7583 16.7581 30.7583 16.0001C30.7584 7.86227 24.138 1.24171 16 1.24171C7.86205 1.24171 1.24168 7.86227 1.24168 16.0001C1.24168 24.1379 7.86205 30.7584 16 30.7584C21.9733 30.7584 27.3199 27.1965 29.6205 21.6844Z"
                fill="#6A5ECC"
                stroke="#6A5ECC"
                strokeWidth="0.183333"
            />
            <path
                d="M15.9996 5.64088C10.2885 5.64088 5.6421 10.2886 5.6421 16.0014C5.6421 21.7127 10.2885 26.3589 15.9996 26.3589C21.7124 26.3589 26.3597 21.7127 26.3597 16.0014C26.3597 10.2887 21.7124 5.64088 15.9996 5.64088ZM15.9996 24.8217C11.1364 24.8217 7.17927 20.8649 7.17927 16.0014C7.17927 11.1362 11.1363 7.17805 15.9996 7.17805C20.8646 7.17805 24.8225 11.1362 24.8225 16.0014C24.8225 20.8649 20.8646 24.8217 15.9996 24.8217Z"
                fill="#6A5ECC"
                stroke="#6A5ECC"
                strokeWidth="0.183333"
            />
            <path
                d="M16.6253 10.9294L16.6252 10.9294C16.4775 10.7247 16.2363 10.6265 16.0014 10.6267C15.7665 10.6269 15.5257 10.7254 15.3784 10.9296L15.3783 10.9297L12.0462 15.5533C12.0462 15.5533 12.0462 15.5533 12.0462 15.5533C11.8526 15.8218 11.8526 16.1838 12.0462 16.4522C12.0462 16.4523 12.0462 16.4523 12.0462 16.4523L15.3766 21.0702L15.3766 21.0702C15.6826 21.4942 16.316 21.4962 16.6235 21.07L16.6235 21.0699L19.9556 16.4463C19.9557 16.4463 19.9557 16.4463 19.9557 16.4463C20.1493 16.1778 20.1493 15.8158 19.9557 15.5473C19.9557 15.5473 19.9557 15.5473 19.9556 15.5473L16.6253 10.9294ZM18.3845 15.9972L16 19.3064L13.6173 16.0024L16.0018 12.6932L18.3845 15.9972Z"
                fill="#6A5ECC"
                stroke="#6A5ECC"
                strokeWidth="0.183333"
            />
        </svg>
    );
};
