import { ChangeEvent, FC, useState } from 'react';
import { saveAs } from 'file-saver';

import { DocFile, LinkButton, UploadButton } from 'src/components';
import { tDoc } from 'src/types';
import { IconArrow } from 'src/icons';
import { VIOLET } from 'src/constants';
import toast, { Toaster } from 'react-hot-toast';

interface IProps {
    id: string;
    tabkey?: string;
    list: tDoc[];
    dockey?: string;
    disabled?: { value: boolean; text: string };
    onChangeDocs?: (data: FormData) => void;
}

export const Docs: FC<IProps> = ({ id, list, tabkey, disabled, dockey, onChangeDocs }) => {
    const [isShowAttachments, setIsShowAttachments] = useState<boolean>(false);
    const notify = (name: string) => toast.error(name);

    const checkFileSize = async (e: ChangeEvent<HTMLInputElement>) => {
        const max = 5;
        const readyFiles = [];
        const files = e.target.files;
        if (files?.length) {
            for (const [key, value] of Object.entries(files)) {
                if (+(parseFloat(value.size.toString()) / (1024 * 1024)).toFixed(2) < max) {
                    readyFiles.push(value);
                } else {
                    console.log('else');
                    notify(`The '${value.name}' file size is more than ${max}MB.`);
                }
            }
        }
        return readyFiles;
    };
    const saveImages = async (e: ChangeEvent<HTMLInputElement>) => {
        const list = await checkFileSize(e);
        const formData = new FormData();
        formData.append(`type_id`, id);
        if (list && tabkey) {
            formData.append(`tab_key`, tabkey);
            for (const [key, value] of Object.entries(list)) {
                formData.append(`files[${key}]`, value);
            }
        }
        onChangeDocs && onChangeDocs(formData);
    };
    return (
        <div className="house__grid house__grid--four house__grid--four-small">
            <Toaster />
            {dockey === 'private' && !disabled?.value && (
                <div className="house__grid_item" key={`upload-${id}-${tabkey}`}>
                    <UploadButton
                        id={`upload-${id}`}
                        text="Add New File"
                        onFileChange={e => saveImages(e)}
                    />
                </div>
            )}
            {list?.length > 4 && !isShowAttachments
                ? list.slice(0, 4).map((el: tDoc, id) => {
                      return (
                          <div className="house__grid_item" key={`doc-file-${el.name}-${id}`}>
                              <DocFile
                                  {...el}
                                  onSave={() => saveAs(el.path, `${el.name}.${el.mime}`)}
                                  disabled={disabled}
                              />
                          </div>
                      );
                  })
                : list?.map((el: tDoc, id) => {
                      return (
                          <div className="house__grid_item" key={`doc-file-${el.name}-${id}`}>
                              <DocFile
                                  {...el}
                                  onSave={() => saveAs(el.path, `${el.name}.${el.mime}`)}
                                  disabled={disabled}
                              />
                          </div>
                      );
                  })}
            {list.length > 4 && (
                <div className="house__grid_item--full">
                    <LinkButton
                        text={isShowAttachments ? 'Show Less' : 'Show More'}
                        className="link-button--s link-button--simple"
                        onClick={() => setIsShowAttachments(isShowAttachments ? false : true)}
                    >
                        <span
                            style={{
                                transform: `rotate(${isShowAttachments ? '90deg' : '-90deg'})`,
                            }}
                        >
                            <IconArrow color={VIOLET} width={20} height={20} />
                        </span>
                    </LinkButton>
                </div>
            )}
        </div>
    );
};
