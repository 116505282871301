import './DocFile.scss';
import { FC } from 'react';

import { ReactComponent as IconFile } from 'src/assets/images/iconFile.svg';
import { IconDownload, IconLock } from 'src/icons';
import { tDoc } from 'src/types';
import { Tooltip } from '../Tooltip';

interface IProps extends tDoc {
    onSave: () => void;
    disabled?: { value: boolean; text: string; private?: boolean };
}
export const DocFile: FC<IProps> = ({ name, size, mime, onSave, disabled }) => {
    return disabled?.value ? (
        <Tooltip text={disabled.text}>
            <div className="doc doc--disabled">
                <div className="doc__left">
                    <span className="doc__left_file">
                        <IconFile />
                        <p className="doc__left_file_name">
                            <IconLock />
                        </p>
                    </span>
                    <div className="doc__left_text">
                        <h3>{disabled?.private ? 'Private' : name}</h3>
                    </div>
                </div>
            </div>
        </Tooltip>
    ) : (
        <div className="doc" onClick={() => onSave()}>
            <div className="doc__left">
                <span className="doc__left_file">
                    <IconFile />
                    <p className="doc__left_file_name">{mime}</p>
                </span>
                <div className="doc__left_text">
                    <h3>{name}</h3>
                    <h5>{size}</h5>
                </div>
            </div>
            <div>
                <IconDownload />
            </div>
        </div>
    );
};
