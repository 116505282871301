export const Verified = () => {
    return (
        <svg
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16 4.5C9.36727 4.5 4 9.86727 4 16.5C4 23.1327 9.36727 28.5 16 28.5C22.6327 28.5 28 23.1327 28 16.5C28 9.86727 22.6327 4.5 16 4.5ZM22.24 13.8491L15.6945 20.6673C15.4873 20.8855 15.1927 20.9945 14.9091 20.9945C14.6691 20.9945 14.4182 20.9182 14.2218 20.7545L9.85818 17.2091C9.38909 16.8273 9.32364 16.14 9.69455 15.6709C10.0764 15.2127 10.7636 15.1364 11.2327 15.5182L14.8218 18.4309L20.6691 12.3327C21.0836 11.9073 21.7818 11.8964 22.2073 12.3C22.6436 12.7145 22.6545 13.4127 22.24 13.8491Z"
                fill="#6A5ECC"
            />
        </svg>
    );
};
