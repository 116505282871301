import { FC } from 'react';

interface IProps {
    color?: string;
}

export const IconView: FC<IProps> = ({ color = '#6A5ECC' }) => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.9093 0.916687H5.21262C4.33873 0.916687 3.6665 1.58891 3.6665 2.4628V19.5372C3.6665 20.4111 4.33873 21.0834 5.21262 21.0834C5.75039 21.0834 6.22095 20.8145 6.48984 20.4111L6.82595 19.8734C6.96039 19.7389 7.09484 19.7389 7.16206 19.8734L7.49817 20.4111C7.76706 20.8817 8.30484 21.0834 8.77539 21.0834H9.17873C9.7165 21.0834 10.1871 20.8145 10.4559 20.4111L10.7921 19.8734C10.9265 19.7389 10.9937 19.7389 11.1282 19.8734L11.4643 20.4111C11.7332 20.8817 12.2037 21.0834 12.7415 21.0834H13.1448C13.6826 21.0834 14.1532 20.8145 14.4221 20.4111L14.7582 19.8734C14.8926 19.7389 15.0271 19.7389 15.0943 19.8734L15.4304 20.4111C15.6993 20.8817 16.1698 21.0834 16.7076 21.0834C17.5815 21.0834 18.2537 20.4111 18.2537 19.5372V2.4628C18.4554 1.58891 17.7832 0.916687 16.9093 0.916687ZM17.1109 19.5372C17.1109 19.7389 16.8421 19.8061 16.7748 19.6717L16.4387 19.1339C16.1698 18.7306 15.6993 18.4617 15.1615 18.4617C14.6237 18.4617 14.1532 18.7306 13.8843 19.1339L13.5482 19.6717C13.5482 19.7389 13.4809 19.7389 13.4137 19.7389H13.0104C12.9432 19.7389 12.8759 19.7389 12.8759 19.6717L12.5398 19.1339C12.2709 18.7306 11.8004 18.4617 11.2626 18.4617C10.7248 18.4617 10.2543 18.7306 9.98539 19.1339L9.64928 19.6717C9.38039 19.7389 9.31317 19.7389 9.24595 19.7389H8.84261C8.77539 19.7389 8.70817 19.7389 8.70817 19.6717L8.37206 19.1339C8.10317 18.7306 7.63262 18.4617 7.09484 18.4617C6.55706 18.4617 6.0865 18.7306 5.81761 19.1339L5.4815 19.6717C5.27984 19.8061 5.01095 19.7389 5.01095 19.5372V2.4628C5.01095 2.32835 5.07817 2.26113 5.21262 2.26113H16.9093C17.0437 2.26113 17.1109 2.32835 17.1109 2.4628V19.5372Z"
                fill={color}
            />
            <path
                d="M15.0944 4.27777H7.02769C6.62436 4.27777 6.35547 4.54666 6.35547 4.94999C6.35547 5.35333 6.62436 5.62222 7.02769 5.62222H15.0944C15.4305 5.62222 15.7666 5.35333 15.7666 4.94999C15.7666 4.54666 15.4305 4.27777 15.0944 4.27777Z"
                fill={color}
            />
            <path
                d="M15.0944 7.63892H7.02769C6.62436 7.63892 6.35547 7.9078 6.35547 8.31114C6.35547 8.71447 6.62436 8.98336 7.02769 8.98336H15.0944C15.4305 8.98336 15.7666 8.71447 15.7666 8.31114C15.7666 7.9078 15.4305 7.63892 15.0944 7.63892Z"
                fill={color}
            />
            <path
                d="M15.0944 11H7.02769C6.62436 11 6.35547 11.3361 6.35547 11.6722C6.35547 12.0083 6.62436 12.3444 7.02769 12.3444H15.0944C15.4305 12.3444 15.7666 12.0083 15.7666 11.6722C15.7666 11.3361 15.4305 11 15.0944 11Z"
                fill={color}
            />
            <path
                d="M15.0944 14.3611H7.02769C6.62436 14.3611 6.35547 14.6973 6.35547 15.0334C6.35547 15.3695 6.62436 15.7056 7.02769 15.7056H15.0944C15.4305 15.7056 15.7666 15.3695 15.7666 15.0334C15.7666 14.6973 15.4305 14.3611 15.0944 14.3611Z"
                fill={color}
            />
        </svg>
    );
};
