import { FC } from 'react';

interface IProps {
    color?: string;
}

export const IconBlock: FC<IProps> = ({ color = '#6A5ECC' }) => {
    return (
        <svg
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M30.2541 28.9479H27.9869V2.19269C27.9869 1.71808 27.6021 1.33331 27.1275 1.33331H7.4522C6.9776 1.33331 6.59283 1.71808 6.59283 2.19269V28.9479H4.32568C3.85108 28.9479 3.46631 29.3327 3.46631 29.8073C3.46631 30.2819 3.85108 30.6666 4.32568 30.6666H30.2541C30.7287 30.6666 31.1135 30.2819 31.1135 29.8073C31.1135 29.3327 30.7287 28.9479 30.2541 28.9479ZM14.3605 28.9479V23.0892H20.2192V28.9479H14.3605ZM21.9379 28.9479V22.2298C21.9379 21.7552 21.5532 21.3704 21.0786 21.3704H13.5011C13.0265 21.3704 12.6417 21.7552 12.6417 22.2298V28.9479H8.31158V3.05206H26.2681V28.9479H21.9379Z"
                fill={color}
            />
            <path
                d="M15.4419 5.13226H11.2305C10.7559 5.13226 10.3711 5.51703 10.3711 5.99164V10.203C10.3711 10.6776 10.7559 11.0624 11.2305 11.0624H15.4419C15.9165 11.0624 16.3012 10.6776 16.3012 10.203V5.99164C16.3012 5.51703 15.9165 5.13226 15.4419 5.13226ZM14.5825 9.34366H12.0898V6.85101H14.5825V9.34366Z"
                fill={color}
            />
            <path
                d="M23.3491 5.13226H19.1377C18.6631 5.13226 18.2783 5.51703 18.2783 5.99164V10.203C18.2783 10.6776 18.6631 11.0624 19.1377 11.0624H23.3491C23.8237 11.0624 24.2085 10.6776 24.2085 10.203V5.99164C24.2085 5.51703 23.8237 5.13226 23.3491 5.13226ZM22.4897 9.34366H19.9971V6.85101H22.4897V9.34366Z"
                fill={color}
            />
            <path
                d="M15.4419 13.1328H11.2305C10.7559 13.1328 10.3711 13.5175 10.3711 13.9921V18.2035C10.3711 18.6781 10.7559 19.0629 11.2305 19.0629H15.4419C15.9165 19.0629 16.3012 18.6781 16.3012 18.2035V13.9921C16.3012 13.5175 15.9165 13.1328 15.4419 13.1328ZM14.5825 17.3441H12.0898V14.8515H14.5825V17.3441Z"
                fill={color}
            />
            <path
                d="M23.3491 13.1328H19.1377C18.6631 13.1328 18.2783 13.5175 18.2783 13.9921V18.2035C18.2783 18.6781 18.6631 19.0629 19.1377 19.0629H23.3491C23.8237 19.0629 24.2085 18.6781 24.2085 18.2035V13.9921C24.2085 13.5175 23.8237 13.1328 23.3491 13.1328ZM22.4897 17.3441H19.9971V14.8515H22.4897V17.3441Z"
                fill={color}
            />
        </svg>
    );
};
