import { Dispatch } from 'redux';
import { apiClient } from 'src/api';
import { tSearchData } from 'src/types';
import { GET_ALL_HOUSES, CLEAR_QUERY } from './action-types';
import { tHousesAction } from './types';

export const changeHousesData = ({ query, data, count, has_more, sort }: tHousesAction) => {
    return {
        type: GET_ALL_HOUSES,
        payload: {
            query,
            data,
            count,
            has_more,
            sort,
        },
    };
};

export const getHousesAsync = (query: tSearchData) => async (dispatch: Dispatch) => {
    let queryString = '';
    Object.keys(query).map(objKey => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        queryString += query[objKey] ? `${objKey}=${query[objKey]}&` : '';
    });
    try {
        const { data } = await apiClient.get(`/search?${queryString}`);
        dispatch(changeHousesData({ query, ...data.response }));
    } catch (e) {
        console.log(e);
    }
};

export const clearQuery = () => {
    return {
        type: CLEAR_QUERY,
    };
};
