import { BackButton, InfoCard, MainButton } from 'src/components';
import { getCommingSoon } from 'src/store';
import { IconToken } from 'src/icons';

import partner_1 from 'src/assets/images/partner_1.png';
import partner_2 from 'src/assets/images/partner_2.png';
import partner_3 from 'src/assets/images/partner_3.png';
import fractionalization from 'src/assets/images/franctionalize.png';

import './Fractionalization.scss';
import { useAppDispatch } from 'src/hooks';
import { useNavigate } from 'react-router-dom';
export const Fractionalization = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    return (
        <div className="app__page fractionalization">
            <div className="container container--small">
                <div style={{ margin: '20px 0' }}>
                    <BackButton text="Back" onClick={() => navigate(-1)} />
                </div>
                <div className="fractionalization__box">
                    <InfoCard
                        title="100%"
                        text="1404 Tokens"
                        icon={<IconToken />}
                        reversed={true}
                    />
                    <p>
                        You currently hold 100% of your 1404 Security Tokens. These are
                        fractionalized securities that can only be listed on a regulatory compliant
                        ATS with the proper SEC paperwork.
                    </p>
                    <img src={fractionalization} />
                    <div className="fractionalization__box_button">
                        <MainButton
                            buttonType="primary"
                            onClick={() => dispatch(getCommingSoon(true))}
                        >
                            Fractionalize
                        </MainButton>
                    </div>
                </div>
                <p className="fractionalization__text">
                    If you are interested in a consultation on this process please{' '}
                    <button onClick={() => dispatch(getCommingSoon(true))}>inquire here</button>.
                </p>
            </div>
            <div className="fractionalization__footer">
                <div className="container container--small">
                    <h6>ATS partners</h6>
                    <p>
                        To begin the onboarding process you can find one of our ATS partners below.
                    </p>
                    <div className="fractionalization__footer_partners">
                        <img src={partner_1} />
                        <img src={partner_2} />
                        <img src={partner_3} />
                    </div>
                </div>
            </div>
        </div>
    );
};
