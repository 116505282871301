import './BaseCard.scss';

import { FC } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { Dots, LinkButton, Slider, Tag, Tooltip } from 'src/components';
import { IconCopy, IconUsers } from 'src/icons';

import { GREY700, VIOLET, RED } from 'src/constants';
import { tHouseFull, tSelect } from 'src/types/tCard/tCard';
import { dotsInText, copyText } from 'src/helpers';

interface IProps {
    item: tHouseFull;
    controls: tSelect;
}
export const BaseCard: FC<IProps> = ({ item, controls }) => {
    const {
        id,
        photos,
        estate_type,
        is_verified,
        vacant,
        company,
        address,
        state,
        hash,
        smart_contract_address,
    } = item;

    return (
        <div className="base-card">
            <div className="base-card__images">
                {photos && photos.length > 1 ? (
                    <Slider
                        className="images-slider--big"
                        items={photos}
                        id={`${id}-${uuidv4()}`}
                    />
                ) : (
                    <div
                        className="base-card__images_img"
                        style={{ backgroundImage: photos && photos[0] ? `url(${photos[0]})` : '' }}
                    />
                )}
                <div className="base-card__images_header">
                    <div>
                        <Tag
                            bgColor={is_verified ? VIOLET : GREY700}
                            textColor="#FFF"
                            text={is_verified ? 'Verified' : 'UNVerified'}
                        />
                        {vacant && (
                            <Tooltip
                                text="This Property is Currently Vacant & Abandoned"
                                minWidth="150"
                            >
                                <Tag bgColor={RED} textColor="#FFF" text="Abandoned" />
                            </Tooltip>
                        )}
                    </div>
                    <div>
                        <div
                            className="base-card__images_header-company"
                            style={{ backgroundImage: `url(${company})` }}
                        />
                    </div>
                </div>
            </div>
            <div className="base-card__content">
                <div className="base-card__content_header">
                    <div className="base-card__content_header-type">
                        <IconUsers />
                        <h5>{estate_type}</h5>
                    </div>
                    {hash && (
                        <LinkButton
                            text={dotsInText(hash, 8)}
                            onClick={() => {
                                smart_contract_address
                                    ? window.open(smart_contract_address, '_blank')
                                    : copyText(hash);
                            }}
                        >
                            <span
                                onClick={e => {
                                    e.stopPropagation();
                                    copyText(hash);
                                }}
                            >
                                <IconCopy />
                            </span>
                        </LinkButton>
                    )}
                </div>
                <div className="base-card__content_body">
                    <h1 style={{ color: vacant ? RED : 'initial' }}>{address}</h1>
                    {controls.length > 0 && (
                        <Tooltip text="Ownership Controls" className="tooltip--top-mobile-left">
                            <Dots menu={controls} />
                        </Tooltip>
                    )}
                </div>
                <h2>{state}</h2>
            </div>
        </div>
    );
};
