import { FC, ChangeEvent } from 'react';

import './UploadButton.scss';
import { IconClose } from 'src/icons';
import { VIOLET } from 'src/constants';

interface IProps {
    id?: string;
    text: string;
    multiple?: boolean;
    accept?: string;
    onFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
}
export const UploadButton: FC<IProps> = ({
    id = 'upolad-button',
    text,
    multiple = true,
    accept = '.jpg,.jpeg,.png,.gif,.bmp,.svg,.doc,.docx,.txt,.rft,.pdf,.csv,.xlsx,.xls',
    onFileChange,
}) => {
    return (
        <label htmlFor={id} className="upload-button">
            <span>
                <IconClose color={VIOLET} />
            </span>
            {text}
            <input
                id={id}
                type="file"
                multiple={multiple}
                onChange={e => onFileChange(e)}
                accept={accept}
            />
        </label>
    );
};
