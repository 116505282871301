export const IconLock = () => {
    return (
        <svg
            width="12"
            height="16"
            viewBox="0 0 12 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.5416 6.2778H10.0555V4.33335C10.0555 2.18863 8.31131 0.444458 6.16658 0.444458C4.02186 0.444458 2.2777 2.18863 2.2777 4.33335V6.2778H1.79159C0.987882 6.2778 0.333252 6.93178 0.333252 7.73613V14.5417C0.333252 15.3461 0.987882 16 1.79159 16H10.5416C11.3453 16 11.9999 15.3461 11.9999 14.5417V7.73613C11.9999 6.93178 11.3453 6.2778 10.5416 6.2778ZM3.57399 4.33335C3.57399 2.90353 4.73677 1.74076 6.16658 1.74076C7.5964 1.74076 8.75918 2.90353 8.75918 4.33335V6.2778H3.57399V4.33335ZM6.81473 11.2828V12.7593C6.81473 13.1171 6.52501 13.4074 6.16658 13.4074C5.80816 13.4074 5.51844 13.1171 5.51844 12.7593V11.2828C5.13279 11.0579 4.87029 10.6444 4.87029 10.1667C4.87029 9.45178 5.45168 8.87039 6.16658 8.87039C6.88149 8.87039 7.46288 9.45178 7.46288 10.1667C7.46288 10.6444 7.20038 11.0579 6.81473 11.2828Z"
                fill="white"
            />
        </svg>
    );
};
