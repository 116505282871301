import { FC } from 'react';

interface IProps {
    color?: string;
    additionColor?: string;
    size?: number;
}

export const IconCalendar: FC<IProps> = ({
    color = '#6A5ECC',
    additionColor = '#1E1231',
    size = 33,
}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M23.5568 3.50611V4.2978H24.3485H25.8912C27.5997 4.2978 28.9889 5.68707 28.9889 7.39551V26.7773C28.9889 28.4857 27.5997 29.875 25.8912 29.875H5.42309C3.71465 29.875 2.32538 28.4857 2.32538 26.7773V7.39551C2.32538 5.68707 3.71465 4.2978 5.42309 4.2978H6.96578H7.75747V3.50611V2.41971C7.75747 2.25941 7.89188 2.12501 8.05218 2.12501C8.21256 2.12501 8.34698 2.2595 8.34698 2.41971V3.50611V4.2978H9.13867H22.1756H22.9673V3.50611V2.41971C22.9673 2.25941 23.1017 2.12501 23.262 2.12501C23.4224 2.12501 23.5568 2.2595 23.5568 2.41971V3.50611ZM7.75747 5.679V4.88731H6.96578H5.42309C4.04069 4.88731 2.91489 6.01311 2.91489 7.39551V10.5679V11.3596H3.70658H27.6076H28.3993V10.5679V7.39551C28.3993 6.01311 27.2735 4.88731 25.8911 4.88731H24.3484H23.5568V5.679V6.7654C23.5568 6.9257 23.4223 7.06011 23.262 7.06011C23.1017 7.06011 22.9673 6.9257 22.9673 6.7654V5.679V4.88731H22.1756H9.13858H8.34689V5.679V6.7654C8.34689 6.9257 8.21248 7.06011 8.05218 7.06011C7.89188 7.06011 7.75747 6.9257 7.75747 6.7654V5.679ZM3.70658 11.949H2.91489V12.7407V26.7773C2.91489 28.1596 4.04069 29.2854 5.42309 29.2854H25.8913C27.2737 29.2854 28.3995 28.1596 28.3995 26.7773V12.7407V11.949H27.6078H3.70658Z"
                fill={additionColor}
                stroke={color}
                strokeWidth="1.58338"
            />
        </svg>
    );
};
