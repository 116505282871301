import { IconNotification } from 'src/icons';
import './Notification.scss';

export const Notification = () => {
    return (
        <p className="notification">
            <IconNotification />
            <span className="notification__counter">2</span>
        </p>
    );
};
