import { useEffect, useState } from 'react';

import { MainButton } from 'src/components';

import { Docs } from '../Docs/Docs';
import { tDocsTab } from 'src/types/tCard';
import './DocsTab.scss';

type tTabs = { title: string; key: string; count: number }[];

export const DocsTab = ({
    id,
    data,
    disabled,
    dockey,
    onChangeDocs,
}: {
    id: string;
    data: tDocsTab;
    dockey: string;
    disabled?: { value: boolean; text: string; private?: boolean };
    onChangeDocs?: (data: FormData) => void;
}) => {
    const [activeTab, setActiveTab] = useState<string>('ownership_docs');
    const [tabs, setTabs] = useState<tTabs>([]);

    const createdTabs = () => {
        const tabs: tTabs = [];
        for (const [key, value] of Object.entries(data)) {
            tabs.push({ title: value.title, key: key, count: value.list.length });
        }
        setTabs(tabs);
        setActiveTab(tabs[0].key);
    };

    useEffect(() => {
        createdTabs();
    }, [data]);

    return (
        <div className="docs-tabs">
            <div className="docs-tabs__header">
                {tabs.map(el => {
                    return (
                        <MainButton
                            key={el.key}
                            buttonType="tab"
                            buttonColor={el.key === activeTab ? 'violet' : 'white'}
                            buttonSize="s"
                            onClick={() => setActiveTab(el.key)}
                        >
                            {el.title} <span className={!el.count ? 'grey' : ''}>{el.count}</span>
                        </MainButton>
                    );
                })}
            </div>
            <Docs
                id={id}
                list={data[activeTab].list}
                dockey={dockey}
                tabkey={activeTab}
                disabled={disabled}
                onChangeDocs={onChangeDocs}
            />
        </div>
    );
};
