import { useRef, useState } from 'react';
import { SelectMain } from 'src/components';
import { useOnClickOutside } from 'src/hooks';
import { tSelect } from 'src/types';

import './Dots.scss';

export const Dots = ({ menu }: { menu: tSelect }) => {
    const [openSelect, setOpenSelect] = useState<boolean>(false);
    const selectClickHandler = () => setOpenSelect(prevState => !prevState);
    const nodeRef = useRef<HTMLDivElement | null>(null);

    useOnClickOutside(nodeRef, () => setOpenSelect(false));

    return (
        <div className="dots-overlay" ref={nodeRef} onClick={selectClickHandler}>
            <div className="dots">
                {[1, 2, 3].map(el => {
                    return <span key={el} />;
                })}
            </div>
            <SelectMain menu={menu} open={openSelect} />
        </div>
    );
};
