import { FC } from 'react';

interface IProps {
    color?: string;
}

export const IconLot: FC<IProps> = ({ color = '#6A5ECC' }) => {
    return (
        <svg
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M29.0499 2.61669H4.09993C3.86566 2.61669 3.64098 2.70975 3.47532 2.87541C3.30967 3.04107 3.2166 3.26575 3.2166 3.50002V28.5C3.2166 28.7343 3.30967 28.959 3.47532 29.1246C3.64098 29.2903 3.86566 29.3834 4.09993 29.3834H29.0999C29.2159 29.3834 29.3308 29.3605 29.438 29.3161C29.5451 29.2717 29.6425 29.2067 29.7245 29.1246C29.8066 29.0426 29.8716 28.9452 29.916 28.8381C29.9604 28.7309 29.9833 28.616 29.9833 28.5V3.50002C29.9833 3.26575 29.8902 3.04107 29.7245 2.87541C29.5589 2.70975 29.3342 2.61669 29.0999 2.61669H29.0499ZM19.8833 4.38335V27.6167H13.3166V4.38335H19.8833ZM4.98327 4.38335H11.5499V27.6167H4.98327V4.38335ZM28.2166 27.6167H21.6499V4.38335H28.2166V27.6167Z"
                fill={color}
                stroke={color}
                strokeWidth="0.1"
            />
        </svg>
    );
};
