export const defaultTabs = [
    {
        title: 'Assets',
        key: 'assets',
    },
    {
        title: 'Credentials',
        key: 'credentials',
    },
    // {
    //     title: 'Claim reNFT',
    //     key: 'claim_reNFT',
    // },
    // {
    //     title: 'Asset Registry',
    //     key: 'asset_registry',
    // },
];
