export const IconNotification = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.4847 10.8484V14.6484C18.4847 14.7903 18.5976 14.9032 18.7395 14.9032C19.9267 14.9032 20.946 15.8 21.0557 16.9419C21.1137 17.5774 20.904 18.2097 20.4782 18.6806C20.0525 19.1484 19.4428 19.4194 18.8073 19.4194H14.917C14.7557 20.8678 13.5234 22 12.0331 22C10.5428 22 9.31048 20.8678 9.14919 19.4194H5.25885C4.6234 19.4194 4.0137 19.1484 3.58791 18.6806C3.15889 18.2097 2.94917 17.5774 3.01048 16.9419C3.12016 15.8 4.13631 14.9032 5.32662 14.9032C5.46857 14.9032 5.58148 14.7871 5.58148 14.6484V10.7097C5.58148 8.94191 6.28147 7.29354 7.55243 6.06773C8.22661 5.41611 9.01694 4.93549 9.87175 4.63225C9.84595 4.49354 9.82984 4.34841 9.82984 4.20323C9.82984 2.98711 10.817 2 12.0331 2C13.2492 2 14.2363 2.98711 14.2363 4.20323C14.2363 4.35164 14.2201 4.5 14.1911 4.64518C16.6782 5.56453 18.4847 8.02579 18.4847 10.8484ZM12.946 4.20318C12.946 3.69998 12.5363 3.29029 12.0331 3.29029C11.5299 3.29029 11.1202 3.69998 11.1201 4.20641C11.1201 4.24554 11.1257 4.28191 11.1317 4.32067L11.133 4.32896C11.5072 4.27736 11.8846 4.2515 12.2685 4.26443C12.4911 4.27089 12.7137 4.29346 12.933 4.32573L12.9343 4.31744C12.9403 4.27867 12.946 4.24231 12.946 4.20318ZM10.4524 19.4193C10.6041 20.1547 11.2557 20.7096 12.0331 20.7096C12.8105 20.7096 13.4621 20.1548 13.6137 19.4193H10.4524ZM19.5266 17.8128C19.7105 17.6096 19.7976 17.3419 19.7718 17.0645C19.7234 16.5774 19.2718 16.1935 18.7395 16.1935C17.8879 16.1935 17.1944 15.5 17.1944 14.6484V10.8484C17.1944 8.02579 14.9621 5.64835 12.2234 5.5516C12.1589 5.54836 12.0976 5.54836 12.0331 5.54836C10.6847 5.54836 9.42016 6.05808 8.4492 6.99677C7.43305 7.97742 6.87178 9.29675 6.87178 10.7097V14.6484C6.87178 15.5 6.17825 16.1935 5.32662 16.1935C4.79439 16.1935 4.33951 16.5774 4.29436 17.0645C4.26856 17.3419 4.35567 17.6096 4.54276 17.8128C4.7299 18.016 4.98148 18.129 5.25891 18.129H18.8073C19.0847 18.129 19.3363 18.0161 19.5266 17.8128Z"
                fill="#1E1231"
            />
        </svg>
    );
};
