export const IconPremium = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.7755 9.34201L13.0965 3.81381C12.7653 2.72873 11.2347 2.72873 10.9149 3.81381L9.22448 9.34201H4.14173C3.03381 9.34201 2.57693 10.7698 3.47926 11.4094L7.63683 14.3791L6.0035 19.6446C5.67227 20.7068 6.90583 21.5634 7.78532 20.8896L12 17.6914L16.2147 20.901C17.0942 21.5749 18.3277 20.7182 17.9965 19.656L16.3632 14.3905L20.5207 11.4208C21.4231 10.7697 20.9662 9.35343 19.8583 9.35343H14.7755V9.34201Z"
                fill="#DEA200"
            />
        </svg>
    );
};
