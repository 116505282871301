export const IconCalendarFull = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.4887 22H5.48356C5.02409 21.9988 4.56938 21.9069 4.14547 21.7297C3.72157 21.5525 3.33679 21.2933 3.0132 20.9671C2.6896 20.641 2.43354 20.2541 2.25969 19.8288C2.08584 19.4035 1.9976 18.9481 2.00005 18.4886V7.3414C2.00496 6.42074 2.37414 5.53946 3.02689 4.89018C3.67965 4.24091 4.56289 3.87645 5.48356 3.87646H18.4887C19.4093 3.87645 20.2926 4.24091 20.9453 4.89018C21.5981 5.53946 21.9673 6.42074 21.9722 7.3414V18.4886C21.9746 18.9481 21.8864 19.4035 21.7125 19.8288C21.5387 20.2541 21.2826 20.641 20.959 20.9671C20.6354 21.2933 20.2507 21.5525 19.8268 21.7297C19.4028 21.9069 18.9481 21.9988 18.4887 22ZM5.48356 5.26987C4.93243 5.26985 4.4036 5.4875 4.01216 5.87546C3.62072 6.26343 3.39835 6.79029 3.39345 7.3414V18.4886C3.38976 18.7654 3.4411 19.0402 3.54448 19.297C3.64787 19.5539 3.80124 19.7876 3.99569 19.9846C4.19014 20.1817 4.4218 20.3381 4.67722 20.4449C4.93264 20.5517 5.20672 20.6066 5.48356 20.6066H18.4887C18.7655 20.6066 19.0396 20.5517 19.295 20.4449C19.5504 20.3381 19.7821 20.1817 19.9765 19.9846C20.171 19.7876 20.3244 19.5539 20.4277 19.297C20.5311 19.0402 20.5825 18.7654 20.5788 18.4886V7.3414C20.5739 6.79029 20.3515 6.26343 19.9601 5.87546C19.5686 5.4875 19.0398 5.26985 18.4887 5.26987H5.48356Z"
                fill="#1E1231"
            />
            <path
                d="M18.4887 22H5.48356C5.02409 21.9988 4.56938 21.9069 4.14547 21.7297C3.72157 21.5525 3.33679 21.2933 3.0132 20.9671C2.6896 20.641 2.43354 20.2541 2.25969 19.8288C2.08584 19.4035 1.9976 18.9481 2.00005 18.4886V9.64516C2.00005 9.46038 2.07345 9.28317 2.20411 9.15252C2.33477 9.02186 2.51197 8.94846 2.69675 8.94846H21.2755C21.4603 8.94846 21.6375 9.02186 21.7681 9.15252C21.8988 9.28317 21.9722 9.46038 21.9722 9.64516V18.4886C21.9746 18.9481 21.8864 19.4035 21.7125 19.8288C21.5387 20.2541 21.2826 20.641 20.959 20.9671C20.6354 21.2933 20.2507 21.5525 19.8268 21.7297C19.4028 21.9069 18.9481 21.9988 18.4887 22ZM3.39345 10.3419V18.4886C3.38976 18.7654 3.4411 19.0402 3.54448 19.297C3.64787 19.5539 3.80124 19.7876 3.99569 19.9846C4.19014 20.1817 4.4218 20.3381 4.67722 20.4449C4.93264 20.5517 5.20672 20.6066 5.48356 20.6066H18.4887C18.7655 20.6066 19.0396 20.5517 19.295 20.4449C19.5504 20.3381 19.7821 20.1817 19.9765 19.9846C20.171 19.7876 20.3244 19.5539 20.4277 19.297C20.5311 19.0402 20.5825 18.7654 20.5788 18.4886V10.314L3.39345 10.3419Z"
                fill="#1E1231"
            />
            <path
                d="M6.877 7.09057C6.6961 7.08833 6.52296 7.01673 6.39332 6.89054C6.26368 6.76436 6.18743 6.59322 6.1803 6.41245V2.6967C6.1803 2.51193 6.2537 2.33472 6.38436 2.20406C6.51501 2.0734 6.69222 2 6.877 2C7.06178 2 7.23899 2.0734 7.36964 2.20406C7.5003 2.33472 7.5737 2.51193 7.5737 2.6967V6.41245C7.56657 6.59322 7.49032 6.76436 7.36068 6.89054C7.23104 7.01673 7.0579 7.08833 6.877 7.09057Z"
                fill="#1E1231"
            />
            <path
                d="M11.9861 7.09057C11.8052 7.08833 11.6321 7.01673 11.5025 6.89054C11.3728 6.76436 11.2966 6.59322 11.2894 6.41245V2.6967C11.2894 2.51193 11.3628 2.33472 11.4935 2.20406C11.6241 2.0734 11.8014 2 11.9861 2C12.1709 2 12.3481 2.0734 12.4788 2.20406C12.6094 2.33472 12.6828 2.51193 12.6828 2.6967V6.41245C12.6757 6.59322 12.5995 6.76436 12.4698 6.89054C12.3402 7.01673 12.167 7.08833 11.9861 7.09057Z"
                fill="#1E1231"
            />
            <path
                d="M17.0953 7.09057C16.9144 7.08833 16.7412 7.01673 16.6116 6.89054C16.4819 6.76436 16.4057 6.59322 16.3986 6.41245V2.6967C16.3986 2.51193 16.472 2.33472 16.6026 2.20406C16.7333 2.0734 16.9105 2 17.0953 2C17.28 2 17.4572 2.0734 17.5879 2.20406C17.7186 2.33472 17.792 2.51193 17.792 2.6967V6.41245C17.7848 6.59322 17.7086 6.76436 17.5789 6.89054C17.4493 7.01673 17.2762 7.08833 17.0953 7.09057Z"
                fill="#1E1231"
            />
            <path
                d="M7.34147 14.522C7.98277 14.522 8.50264 14.0022 8.50264 13.3609C8.50264 12.7196 7.98277 12.1997 7.34147 12.1997C6.70017 12.1997 6.1803 12.7196 6.1803 13.3609C6.1803 14.0022 6.70017 14.522 7.34147 14.522Z"
                fill="#1E1231"
            />
            <path
                d="M11.9861 14.522C12.6274 14.522 13.1473 14.0022 13.1473 13.3609C13.1473 12.7196 12.6274 12.1997 11.9861 12.1997C11.3448 12.1997 10.825 12.7196 10.825 13.3609C10.825 14.0022 11.3448 14.522 11.9861 14.522Z"
                fill="#1E1231"
            />
            <path
                d="M16.6308 14.522C17.2721 14.522 17.7919 14.0022 17.7919 13.3609C17.7919 12.7196 17.2721 12.1997 16.6308 12.1997C15.9895 12.1997 15.4696 12.7196 15.4696 13.3609C15.4696 14.0022 15.9895 14.522 16.6308 14.522Z"
                fill="#1E1231"
            />
            <path
                d="M7.34147 18.2378C7.98277 18.2378 8.50264 17.7179 8.50264 17.0766C8.50264 16.4353 7.98277 15.9155 7.34147 15.9155C6.70017 15.9155 6.1803 16.4353 6.1803 17.0766C6.1803 17.7179 6.70017 18.2378 7.34147 18.2378Z"
                fill="#1E1231"
            />
            <path
                d="M11.9861 18.2378C12.6274 18.2378 13.1473 17.7179 13.1473 17.0766C13.1473 16.4353 12.6274 15.9155 11.9861 15.9155C11.3448 15.9155 10.825 16.4353 10.825 17.0766C10.825 17.7179 11.3448 18.2378 11.9861 18.2378Z"
                fill="#1E1231"
            />
            <path
                d="M16.6308 18.2378C17.2721 18.2378 17.7919 17.7179 17.7919 17.0766C17.7919 16.4353 17.2721 15.9155 16.6308 15.9155C15.9895 15.9155 15.4696 16.4353 15.4696 17.0766C15.4696 17.7179 15.9895 18.2378 16.6308 18.2378Z"
                fill="#1E1231"
            />
        </svg>
    );
};
