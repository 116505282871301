import { tComment } from 'src/types';
import Moment from 'react-moment';

import defaultUser from 'src/assets/images/defaultUser.png';
import './Comments.scss';
export const Comment = ({ data }: { data: tComment }) => {
    const { photo, full_name, text, created_at } = data;
    return (
        <div className="comment">
            <div
                className="comment__pic"
                style={{
                    backgroundImage: `url(${photo ? photo : defaultUser})`,
                }}
            />
            <div>
                <h6>
                    {full_name}
                    <span>
                        <Moment format="DD/MM/YY">{created_at}</Moment> at
                        <Moment format="h:mm a">{created_at}</Moment>
                    </span>
                </h6>
                <div className="comment__message" dangerouslySetInnerHTML={{ __html: text }}></div>
            </div>
        </div>
    );
};
