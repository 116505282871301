import './SearchUser.scss';

import { useState, useRef } from 'react';
import { Loader, MainInput } from 'src/components';

import { useOnClickOutside } from 'src/hooks';
import defaultUser from 'src/assets/images/defaultUser.png';
import { Link } from 'react-router-dom';
import { getUsers } from 'src/api';

import './SearchUser.scss';

export const SearchUser = () => {
    const [isFocus, setIsFocus] = useState<boolean>(false);
    const nodeRef = useRef<HTMLElement | any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [users, setUsers] = useState<
        { id: string; first_name: string; last_name: string; profile_picture: string }[]
    >([]);

    useOnClickOutside(nodeRef, () => setIsFocus(false));

    const updateUsers = async (val: string) => {
        setLoading(true);
        await getUsers(val)
            .then(({ data }) => {
                setUsers(data.response);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="search-user" ref={nodeRef}>
            <MainInput
                placeholder="Search user by name or wallet address"
                sufix={loading ? <Loader /> : ''}
                isFocus={isFocus}
                style={{ padding: '0 10px' }}
                changeFocus={(focus: boolean) => setIsFocus(focus)}
                onChangeValue={(val: string) => updateUsers(val)}
            />
            {isFocus && users.length > 0 && (
                <div className="search-user__select">
                    <div className="search-user__select_content">
                        {users.map((el, idx) => {
                            return (
                                <Link
                                    to={`user?id=${el.id}`}
                                    key={idx}
                                    className="search-user__select_content-item"
                                    onClick={() => {
                                        setIsFocus(false);
                                    }}
                                >
                                    <div
                                        className="search-user__select_content-item-picture"
                                        style={{
                                            backgroundImage: `url(${
                                                el.profile_picture
                                                    ? el.profile_picture
                                                    : defaultUser
                                            })`,
                                        }}
                                    />
                                    {el.first_name} {el.last_name}
                                </Link>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};
