export const Logout = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.35185 14.537H6.11111C5.75463 14.537 5.46296 14.2454 5.46296 13.8889L5.46296 4.44444C5.46296 4.08796 5.75463 3.7963 6.11111 3.7963H9.35185C9.70833 3.7963 10 3.50463 10 3.14815C10 2.79167 9.70833 2.5 9.35185 2.5L5.46296 2.5C4.75 2.5 4.16667 3.08333 4.16667 3.7963L4.16667 14.537C4.16667 15.25 4.75 15.8333 5.46296 15.8333H9.35185C9.70833 15.8333 10 15.5417 10 15.1852C10 14.8287 9.70833 14.537 9.35185 14.537Z"
                fill="#6A5ECC"
            />
            <path
                d="M13.5511 9.80563L12.2937 11.0009C12.0329 11.2489 12.0329 11.6494 12.2937 11.8974C12.5545 12.1453 12.9759 12.1453 13.2368 11.8974L15.6379 9.61489C15.8987 9.36694 15.8987 8.96639 15.6379 8.71844L13.2368 6.43597C12.9759 6.18801 12.5545 6.18801 12.2937 6.43597C12.0329 6.68392 12.0329 7.08447 12.2937 7.33242L13.5511 8.53406H7.75233C7.38447 8.53406 7.0835 8.82016 7.0835 9.16985C7.0835 9.51953 7.38447 9.80563 7.75233 9.80563H13.5511Z"
                fill="#6A5ECC"
            />
        </svg>
    );
};
