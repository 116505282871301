export const coordsBergen = [
    { lng: -74.0875244140624, lat: 40.7841911315919 },
    { lng: -74.0878143310546, lat: 40.7839279174806 },
    { lng: -74.0880737304688, lat: 40.7836647033693 },
    { lng: -74.0881500244141, lat: 40.7836456298828 },
    { lng: -74.0883789062499, lat: 40.7830123901367 },
    { lng: -74.0897216796874, lat: 40.781665802002 },
    { lng: -74.0897216796874, lat: 40.7813873291016 },
    { lng: -74.0905990600585, lat: 40.7802429199219 },
    { lng: -74.0911102294922, lat: 40.7799987792969 },
    { lng: -74.0922546386719, lat: 40.7775001525879 },
    { lng: -74.0924987792968, lat: 40.7763900756836 },
    { lng: -74.0921783447266, lat: 40.7752532958984 },
    { lng: -74.0919799804688, lat: 40.774383544922 },
    { lng: -74.091667175293, lat: 40.7719459533692 },
    { lng: -74.091667175293, lat: 40.7713890075684 },
    { lng: -74.0908355712891, lat: 40.7691650390626 },
    { lng: -74.0908355712891, lat: 40.7688903808593 },
    { lng: -74.0905532836914, lat: 40.7686119079589 },
    { lng: -74.0905532836914, lat: 40.7683334350586 },
    { lng: -74.0899963378906, lat: 40.7677764892578 },
    { lng: -74.0899963378906, lat: 40.7663879394532 },
    { lng: -74.0897216796874, lat: 40.7661094665528 },
    { lng: -74.0897216796874, lat: 40.7641677856445 },
    { lng: -74.0899963378906, lat: 40.7638893127441 },
    { lng: -74.0899963378906, lat: 40.7636108398438 },
    { lng: -74.0905532836914, lat: 40.763053894043 },
    { lng: -74.0921173095703, lat: 40.7624740600586 },
    { lng: -74.0933303833007, lat: 40.7625007629394 },
    { lng: -74.0938873291016, lat: 40.7625007629394 },
    { lng: -74.0941696166992, lat: 40.762222290039 },
    { lng: -74.0947189331055, lat: 40.762222290039 },
    { lng: -74.0952758789062, lat: 40.76277923584 },
    { lng: -74.0955581665038, lat: 40.76277923584 },
    { lng: -74.0961074829101, lat: 40.7633323669434 },
    { lng: -74.0961074829101, lat: 40.7638893127441 },
    { lng: -74.0963897705078, lat: 40.7641677856445 },
    { lng: -74.0963897705078, lat: 40.7647209167481 },
    { lng: -74.0980529785156, lat: 40.7663879394532 },
    { lng: -74.0980529785156, lat: 40.7666664123536 },
    { lng: -74.0983352661133, lat: 40.766944885254 },
    { lng: -74.0983352661133, lat: 40.7672233581544 },
    { lng: -74.0980529785156, lat: 40.7675018310548 },
    { lng: -74.0980529785156, lat: 40.7680549621582 },
    { lng: -74.0977783203125, lat: 40.7683334350586 },
    { lng: -74.0977783203125, lat: 40.7694435119629 },
    { lng: -74.0975036621094, lat: 40.7697219848633 },
    { lng: -74.0975036621094, lat: 40.7700004577637 },
    { lng: -74.0972213745117, lat: 40.7702789306641 },
    { lng: -74.0969467163086, lat: 40.7702789306641 },
    { lng: -74.0961074829101, lat: 40.771110534668 },
    { lng: -74.0969467163086, lat: 40.7719459533692 },
    { lng: -74.0969467163086, lat: 40.7722206115724 },
    { lng: -74.0966644287109, lat: 40.7724990844728 },
    { lng: -74.0966644287109, lat: 40.7727775573732 },
    { lng: -74.0961074829101, lat: 40.7733345031739 },
    { lng: -74.0961074829101, lat: 40.7736129760743 },
    { lng: -74.0955581665038, lat: 40.7741661071777 },
    { lng: -74.0955581665038, lat: 40.7744445800781 },
    { lng: -74.0961074829101, lat: 40.7750015258788 },
    { lng: -74.0963897705078, lat: 40.7750015258788 },
    { lng: -74.0972213745117, lat: 40.7758331298829 },
    { lng: -74.0972213745117, lat: 40.7761116027832 },
    { lng: -74.0977783203125, lat: 40.776668548584 },
    { lng: -74.0991668701171, lat: 40.776668548584 },
    { lng: -74.0997238159179, lat: 40.7761116027832 },
    { lng: -74.0999984741211, lat: 40.7761116027832 },
    { lng: -74.1002807617188, lat: 40.7758331298829 },
    { lng: -74.1002807617188, lat: 40.7755546569825 },
    { lng: -74.1005554199219, lat: 40.7752761840821 },
    { lng: -74.1005554199219, lat: 40.7750015258788 },
    { lng: -74.100830078125, lat: 40.7747230529785 },
    { lng: -74.100830078125, lat: 40.7744445800781 },
    { lng: -74.1011123657227, lat: 40.7741661071777 },
    { lng: -74.1011123657227, lat: 40.7738876342773 },
    { lng: -74.1015548706054, lat: 40.7734451293946 },
    { lng: -74.1015014648438, lat: 40.774299621582 },
    { lng: -74.1020965576172, lat: 40.7765693664552 },
    { lng: -74.1136322021484, lat: 40.7747993469239 },
    { lng: -74.1233673095703, lat: 40.768928527832 },
    { lng: -74.1276168823242, lat: 40.7698516845703 },
    { lng: -74.131233215332, lat: 40.7757492065431 },
    { lng: -74.1348571777344, lat: 40.7784805297852 },
    { lng: -74.1396865844726, lat: 40.7834892272949 },
    { lng: -74.1469573974608, lat: 40.7848701477051 },
    { lng: -74.1438903808593, lat: 40.7912101745606 },
    { lng: -74.1377792358398, lat: 40.7993621826173 },
    { lng: -74.1383666992187, lat: 40.8039016723633 },
    { lng: -74.1383361816406, lat: 40.8084297180177 },
    { lng: -74.1346664428711, lat: 40.8156814575195 },
    { lng: -74.132827758789, lat: 40.8188514709473 },
    { lng: -74.1279602050781, lat: 40.8202018737794 },
    { lng: -74.1261367797851, lat: 40.8206520080568 },
    { lng: -74.1255264282227, lat: 40.821102142334 },
    { lng: -74.1243133544922, lat: 40.8219985961915 },
    { lng: -74.1237030029297, lat: 40.822910308838 },
    { lng: -74.1230773925781, lat: 40.8242721557617 },
    { lng: -74.1224670410156, lat: 40.8256301879883 },
    { lng: -74.1218566894531, lat: 40.826530456543 },
    { lng: -74.1218490600585, lat: 40.8274421691895 },
    { lng: -74.1212310791016, lat: 40.8292503356935 },
    { lng: -74.121223449707, lat: 40.8301620483398 },
    { lng: -74.121223449707, lat: 40.831069946289 },
    { lng: -74.121223449707, lat: 40.8324317932129 },
    { lng: -74.1218185424805, lat: 40.8333396911622 },
    { lng: -74.1248168945312, lat: 40.8387908935547 },
    { lng: -74.1229629516602, lat: 40.8478507995606 },
    { lng: -74.1229476928711, lat: 40.8487586975098 },
    { lng: -74.1229400634766, lat: 40.8510284423829 },
    { lng: -74.1235275268555, lat: 40.8542098999023 },
    { lng: -74.1229019165038, lat: 40.8578300476075 },
    { lng: -74.1192474365234, lat: 40.8587303161622 },
    { lng: -74.1186370849608, lat: 40.8591804504396 },
    { lng: -74.117431640625, lat: 40.858268737793 },
    { lng: -74.11319732666, lat: 40.8528213500976 },
    { lng: -74.1095428466796, lat: 40.8559799194337 },
    { lng: -74.1149291992188, lat: 40.8723297119141 },
    { lng: -74.1191558837891, lat: 40.8773307800292 },
    { lng: -74.119758605957, lat: 40.8777809143067 },
    { lng: -74.1239929199219, lat: 40.8823394775392 },
    { lng: -74.1246032714844, lat: 40.8823394775392 },
    { lng: -74.1251983642578, lat: 40.8827896118164 },
    { lng: -74.1264114379883, lat: 40.8832511901855 },
    { lng: -74.1270217895507, lat: 40.8837089538575 },
    { lng: -74.1282272338867, lat: 40.8841590881349 },
    { lng: -74.1294403076172, lat: 40.8850708007812 },
    { lng: -74.1300430297851, lat: 40.8859786987306 },
    { lng: -74.1306533813475, lat: 40.8873405456544 },
    { lng: -74.1324157714844, lat: 40.8964195251465 },
    { lng: -74.1330261230468, lat: 40.897331237793 },
    { lng: -74.1336288452148, lat: 40.8987007141113 },
    { lng: -74.1336212158203, lat: 40.8996009826661 },
    { lng: -74.1336059570311, lat: 40.9018707275391 },
    { lng: -74.1329574584961, lat: 40.9113998413086 },
    { lng: -74.131706237793, lat: 40.9177398681641 },
    { lng: -74.1316909790038, lat: 40.9200096130372 },
    { lng: -74.1328964233398, lat: 40.9222793579102 },
    { lng: -74.1341094970703, lat: 40.9227409362793 },
    { lng: -74.1365432739257, lat: 40.9231986999512 },
    { lng: -74.1407928466797, lat: 40.9245719909669 },
    { lng: -74.1426010131836, lat: 40.9268493652344 },
    { lng: -74.1425857543945, lat: 40.9282112121583 },
    { lng: -74.1419830322265, lat: 40.9295692443849 },
    { lng: -74.1407623291014, lat: 40.9309196472167 },
    { lng: -74.140151977539, lat: 40.9313812255861 },
    { lng: -74.1395263671875, lat: 40.9327392578124 },
    { lng: -74.1395263671875, lat: 40.9336395263673 },
    { lng: -74.1401290893555, lat: 40.9345512390136 },
    { lng: -74.1425476074218, lat: 40.9381904602051 },
    { lng: -74.1437530517578, lat: 40.9404602050782 },
    { lng: -74.1449661254883, lat: 40.9409217834473 },
    { lng: -74.1461791992187, lat: 40.9413795471193 },
    { lng: -74.1480026245117, lat: 40.9413795471193 },
    { lng: -74.1504287719727, lat: 40.943199157715 },
    { lng: -74.1498107910156, lat: 40.9450111389161 },
    { lng: -74.1479797363281, lat: 40.9463691711425 },
    { lng: -74.1467590332031, lat: 40.9472694396974 },
    { lng: -74.1448974609375, lat: 40.9540786743165 },
    { lng: -74.1418380737305, lat: 40.9576988220215 },
    { lng: -74.1412200927734, lat: 40.9608688354493 },
    { lng: -74.1393661499023, lat: 40.964038848877 },
    { lng: -74.1448287963867, lat: 40.9676818847656 },
    { lng: -74.1490859985352, lat: 40.9695091247559 },
    { lng: -74.1618270874023, lat: 40.9772605895997 },
    { lng: -74.1648559570312, lat: 40.9790802001953 },
    { lng: -74.1685104370116, lat: 40.978630065918 },
    { lng: -74.1734085083008, lat: 40.9736595153809 },
    { lng: -74.1983413696288, lat: 40.9764404296875 },
    { lng: -74.2080764770507, lat: 40.9773712158203 },
    { lng: -74.2251129150391, lat: 40.9783210754395 },
    { lng: -74.2366561889648, lat: 40.9824218750001 },
    { lng: -74.2494125366211, lat: 40.9910697937012 },
    { lng: -74.2512283325195, lat: 40.9933395385743 },
    { lng: -74.2554779052734, lat: 40.9978904724121 },
    { lng: -74.2578964233398, lat: 41.0010719299318 },
    { lng: -74.2591171264648, lat: 41.0033416748047 },
    { lng: -74.2645568847656, lat: 41.012420654297 },
    { lng: -74.2669982910156, lat: 41.0142402648927 },
    { lng: -74.2712478637695, lat: 41.0174217224122 },
    { lng: -74.2730712890625, lat: 41.0183296203613 },
    { lng: -74.2706222534179, lat: 41.0233192443848 },
    { lng: -74.2693862915038, lat: 41.0255889892579 },
    { lng: -74.2620391845703, lat: 41.0396308898927 },
    { lng: -74.2552871704101, lat: 41.0532302856446 },
    { lng: -74.2540664672852, lat: 41.0545921325684 },
    { lng: -74.2509994506835, lat: 41.0609397888184 },
    { lng: -74.2503890991211, lat: 41.0627517700196 },
    { lng: -74.2497711181641, lat: 41.0641098022462 },
    { lng: -74.2485427856445, lat: 41.0663795471191 },
    { lng: -74.2460861206055, lat: 41.0704612731934 },
    { lng: -74.2448577880859, lat: 41.0722694396973 },
    { lng: -74.2417907714844, lat: 41.0790710449219 },
    { lng: -74.2405624389648, lat: 41.0804290771484 },
    { lng: -74.2362594604492, lat: 41.0894889831543 },
    { lng: -74.231346130371, lat: 41.0990104675293 },
    { lng: -74.2295074462891, lat: 41.1021804809571 },
    { lng: -74.2196807861328, lat: 41.1212196350099 },
    { lng: -74.2129211425781, lat: 41.1343612670898 },
    { lng: -74.1532821655273, lat: 41.1097602844239 },
    { lng: -74.0245895385742, lat: 41.0520706176758 },
    { lng: -74.0004577636718, lat: 41.040901184082 },
    { lng: -73.984146118164, lat: 41.0339088439943 },
    { lng: -73.9057464599609, lat: 40.9993705749513 },
    { lng: -73.9015197753906, lat: 40.9975891113281 },
    { lng: -73.8936309814453, lat: 40.9965019226075 },
    { lng: -73.8964462280273, lat: 40.9808197021484 },
    { lng: -73.8992233276367, lat: 40.9725799560548 },
    { lng: -73.9060974121094, lat: 40.9521598815918 },
    { lng: -73.9101715087891, lat: 40.9400520324708 },
    { lng: -73.9172210693358, lat: 40.9201011657715 },
    { lng: -73.9213790893554, lat: 40.9083404541016 },
    { lng: -73.927520751953, lat: 40.8961906433107 },
    { lng: -73.9340362548828, lat: 40.8822708129883 },
    { lng: -73.9357757568359, lat: 40.8785820007325 },
    { lng: -73.9485473632812, lat: 40.8535690307618 },
    { lng: -73.9624328613281, lat: 40.8294906616212 },
    { lng: -73.9639434814453, lat: 40.8268699645997 },
    { lng: -73.969970703125, lat: 40.8164100646973 },
    { lng: -73.9768524169922, lat: 40.806510925293 },
    { lng: -73.9848175048828, lat: 40.796112060547 },
    { lng: -73.9865570068359, lat: 40.7937889099121 },
    { lng: -73.9908905029297, lat: 40.795680999756 },
    { lng: -73.9896469116211, lat: 40.7988510131836 },
    { lng: -74.002326965332, lat: 40.8093414306641 },
    { lng: -74.0083770751953, lat: 40.8111801147462 },
    { lng: -74.015022277832, lat: 40.8180007934571 },
    { lng: -74.0174484252929, lat: 40.8184700012208 },
    { lng: -74.0229492187499, lat: 40.8139495849609 },
    { lng: -74.0302810668945, lat: 40.8125648498536 },
    { lng: -74.0302810668945, lat: 40.8130569458008 },
    { lng: -74.0299987792969, lat: 40.8133316040038 },
    { lng: -74.0299987792969, lat: 40.8136100769044 },
    { lng: -74.0297241210937, lat: 40.8138885498048 },
    { lng: -74.0297241210937, lat: 40.8141670227052 },
    { lng: -74.029441833496, lat: 40.8144454956056 },
    { lng: -74.029441833496, lat: 40.814723968506 },
    { lng: -74.0291671752929, lat: 40.814998626709 },
    { lng: -74.0291671752929, lat: 40.8161125183105 },
    { lng: -74.0288925170897, lat: 40.8163871765137 },
    { lng: -74.0288925170897, lat: 40.8166656494141 },
    { lng: -74.0291671752929, lat: 40.8169441223145 },
    { lng: -74.0291671752929, lat: 40.8183326721193 },
    { lng: -74.029441833496, lat: 40.8186111450196 },
    { lng: -74.029441833496, lat: 40.81888961792 },
    { lng: -74.0299987792969, lat: 40.8194427490234 },
    { lng: -74.0299987792969, lat: 40.8199996948243 },
    { lng: -74.0302810668945, lat: 40.8202781677247 },
    { lng: -74.0305557250977, lat: 40.8205566406251 },
    { lng: -74.0308303833008, lat: 40.8205566406251 },
    { lng: -74.0330581665038, lat: 40.8227767944337 },
    { lng: -74.0330581665038, lat: 40.823055267334 },
    { lng: -74.033332824707, lat: 40.8233337402344 },
    { lng: -74.033332824707, lat: 40.8236122131348 },
    { lng: -74.0338897705078, lat: 40.8241653442384 },
    { lng: -74.0338897705078, lat: 40.8244438171388 },
    { lng: -74.0336074829102, lat: 40.8250007629395 },
    { lng: -74.0338897705078, lat: 40.8252792358399 },
    { lng: -74.0338897705078, lat: 40.8261108398437 },
    { lng: -74.0341644287109, lat: 40.8263893127443 },
    { lng: -74.0341644287109, lat: 40.826946258545 },
    { lng: -74.0338897705078, lat: 40.827220916748 },
    { lng: -74.0338897705078, lat: 40.8274993896484 },
    { lng: -74.0336074829102, lat: 40.8277778625488 },
    { lng: -74.0336074829102, lat: 40.8280563354492 },
    { lng: -74.033332824707, lat: 40.8280563354492 },
    { lng: -74.0325012207031, lat: 40.8288879394532 },
    { lng: -74.0322189331054, lat: 40.8288879394532 },
    { lng: -74.0313873291016, lat: 40.8297233581544 },
    { lng: -74.0313873291016, lat: 40.8300018310547 },
    { lng: -74.0311126708984, lat: 40.8302764892579 },
    { lng: -74.0311126708984, lat: 40.8308334350587 },
    { lng: -74.0302810668945, lat: 40.8316650390625 },
    { lng: -74.0299987792969, lat: 40.8316650390625 },
    { lng: -74.0291671752929, lat: 40.8325004577636 },
    { lng: -74.0291671752929, lat: 40.8327789306642 },
    { lng: -74.0288925170897, lat: 40.8330574035646 },
    { lng: -74.0288925170897, lat: 40.8333320617676 },
    { lng: -74.0286102294921, lat: 40.833610534668 },
    { lng: -74.0286102294921, lat: 40.8341674804688 },
    { lng: -74.0283355712891, lat: 40.8344459533691 },
    { lng: -74.0283355712891, lat: 40.8358345031739 },
    { lng: -74.0286102294921, lat: 40.8361129760743 },
    { lng: -74.0286102294921, lat: 40.8363876342775 },
    { lng: -74.0288925170897, lat: 40.8366661071778 },
    { lng: -74.0288925170897, lat: 40.8369445800782 },
    { lng: -74.0291671752929, lat: 40.8372230529786 },
    { lng: -74.029441833496, lat: 40.837501525879 },
    { lng: -74.029441833496, lat: 40.8383331298828 },
    { lng: -74.0297241210937, lat: 40.8386116027831 },
    { lng: -74.0297241210937, lat: 40.8391685485841 },
    { lng: -74.0299987792969, lat: 40.8394432067872 },
    { lng: -74.0299987792969, lat: 40.8397216796875 },
    { lng: -74.0297241210937, lat: 40.8400001525879 },
    { lng: -74.0297241210937, lat: 40.8405570983887 },
    { lng: -74.0291671752929, lat: 40.8411102294922 },
    { lng: -74.0291671752929, lat: 40.8413887023926 },
    { lng: -74.0288925170897, lat: 40.841667175293 },
    { lng: -74.0288925170897, lat: 40.8419456481934 },
    { lng: -74.0286102294921, lat: 40.8422241210938 },
    { lng: -74.0286102294921, lat: 40.8436126708986 },
    { lng: -74.0288925170897, lat: 40.8438873291016 },
    { lng: -74.0288925170897, lat: 40.8452758789063 },
    { lng: -74.0286102294921, lat: 40.8455543518067 },
    { lng: -74.0286102294921, lat: 40.8463897705078 },
    { lng: -74.0288925170897, lat: 40.8466682434082 },
    { lng: -74.0288925170897, lat: 40.8469429016114 },
    { lng: -74.0291671752929, lat: 40.8472213745118 },
    { lng: -74.0291671752929, lat: 40.8488883972169 },
    { lng: -74.0299987792969, lat: 40.8497238159181 },
    { lng: -74.0302810668945, lat: 40.8497238159181 },
    { lng: -74.0302810668945, lat: 40.8494453430177 },
    { lng: -74.0308303833008, lat: 40.8488883972169 },
    { lng: -74.0308303833008, lat: 40.8469429016114 },
    { lng: -74.0313873291016, lat: 40.8463897705078 },
    { lng: -74.0313873291016, lat: 40.8461112976074 },
    { lng: -74.0305557250977, lat: 40.8452758789063 },
    { lng: -74.0305557250977, lat: 40.8444442749023 },
    { lng: -74.0308303833008, lat: 40.844165802002 },
    { lng: -74.0308303833008, lat: 40.8436126708986 },
    { lng: -74.0305557250977, lat: 40.8433341979982 },
    { lng: -74.0305557250977, lat: 40.842498779297 },
    { lng: -74.0308303833008, lat: 40.8422241210938 },
    { lng: -74.0308303833008, lat: 40.841667175293 },
    { lng: -74.0311126708984, lat: 40.8413887023926 },
    { lng: -74.0311126708984, lat: 40.8405570983887 },
    { lng: -74.0313873291016, lat: 40.8402786254883 },
    { lng: -74.0313873291016, lat: 40.8397216796875 },
    { lng: -74.0316696166992, lat: 40.8394432067872 },
    { lng: -74.0316696166992, lat: 40.8386116027831 },
    { lng: -74.0319442749022, lat: 40.8383331298828 },
    { lng: -74.0319442749022, lat: 40.8372230529786 },
    { lng: -74.0316696166992, lat: 40.8369445800782 },
    { lng: -74.0316696166992, lat: 40.8366661071778 },
    { lng: -74.0313873291016, lat: 40.8363876342775 },
    { lng: -74.0313873291016, lat: 40.8361129760743 },
    { lng: -74.0308303833008, lat: 40.8355560302735 },
    { lng: -74.0308303833008, lat: 40.8347206115723 },
    { lng: -74.0305557250977, lat: 40.8344459533691 },
    { lng: -74.0305557250977, lat: 40.8330574035646 },
    { lng: -74.0319442749022, lat: 40.8316650390625 },
    { lng: -74.0322189331054, lat: 40.8316650390625 },
    { lng: -74.0325012207031, lat: 40.8313903808594 },
    { lng: -74.0327758789062, lat: 40.8313903808594 },
    { lng: -74.033332824707, lat: 40.8308334350587 },
    { lng: -74.0344467163086, lat: 40.8308334350587 },
    { lng: -74.0350036621094, lat: 40.8302764892579 },
    { lng: -74.0352783203124, lat: 40.8302764892579 },
    { lng: -74.036392211914, lat: 40.8291664123536 },
    { lng: -74.036392211914, lat: 40.8288879394532 },
    { lng: -74.0366668701172, lat: 40.8286094665528 },
    { lng: -74.0366668701172, lat: 40.8266677856446 },
    { lng: -74.0369415283203, lat: 40.8263893127443 },
    { lng: -74.0369415283203, lat: 40.8261108398437 },
    { lng: -74.0366668701172, lat: 40.8258323669433 },
    { lng: -74.0366668701172, lat: 40.8252792358399 },
    { lng: -74.0363845825195, lat: 40.8229713439941 },
    { lng: -74.0355529785156, lat: 40.8216667175293 },
    { lng: -74.0347213745117, lat: 40.8211097717285 },
    { lng: -74.0344467163086, lat: 40.8208351135255 },
    { lng: -74.0344467163086, lat: 40.8205566406251 },
    { lng: -74.0341644287109, lat: 40.8202781677247 },
    { lng: -74.0341644287109, lat: 40.8199996948243 },
    { lng: -74.033332824707, lat: 40.8191680908204 },
    { lng: -74.033332824707, lat: 40.81888961792 },
    { lng: -74.0330581665038, lat: 40.81888961792 },
    { lng: -74.0327758789062, lat: 40.8186111450196 },
    { lng: -74.0325012207031, lat: 40.8186111450196 },
    { lng: -74.0322189331054, lat: 40.8183326721193 },
    { lng: -74.0319442749022, lat: 40.8180541992189 },
    { lng: -74.0319442749022, lat: 40.8166656494141 },
    { lng: -74.0322189331054, lat: 40.8163871765137 },
    { lng: -74.0322189331054, lat: 40.8152770996094 },
    { lng: -74.0325012207031, lat: 40.814998626709 },
    { lng: -74.0322189331054, lat: 40.814723968506 },
    { lng: -74.0327758789062, lat: 40.8141670227052 },
    { lng: -74.0330581665038, lat: 40.8138885498048 },
    { lng: -74.0330581665038, lat: 40.8133316040038 },
    { lng: -74.0327758789062, lat: 40.8130569458008 },
    { lng: -74.0338897705078, lat: 40.8119430541993 },
    { lng: -74.0338897705078, lat: 40.8116683959961 },
    { lng: -74.0341644287109, lat: 40.8113899230957 },
    { lng: -74.0341644287109, lat: 40.8111114501953 },
    { lng: -74.0344467163086, lat: 40.810832977295 },
    { lng: -74.0344467163086, lat: 40.8105545043946 },
    { lng: -74.0350036621094, lat: 40.810001373291 },
    { lng: -74.0355529785156, lat: 40.8094444274902 },
    { lng: -74.0358352661132, lat: 40.8094444274902 },
    { lng: -74.0361099243163, lat: 40.8091659545898 },
    { lng: -74.0366668701172, lat: 40.8091659545898 },
    { lng: -74.0369415283203, lat: 40.8088874816895 },
    { lng: -74.0391693115234, lat: 40.8088874816895 },
    { lng: -74.0394439697265, lat: 40.8086128234864 },
    { lng: -74.0411148071289, lat: 40.8086128234864 },
    { lng: -74.0413894653319, lat: 40.8088874816895 },
    { lng: -74.0416641235351, lat: 40.8088874816895 },
    { lng: -74.0419464111328, lat: 40.8091659545898 },
    { lng: -74.0427780151367, lat: 40.8091659545898 },
    { lng: -74.0430526733398, lat: 40.8088874816895 },
    { lng: -74.0436096191406, lat: 40.8088874816895 },
    { lng: -74.0438919067383, lat: 40.8086128234864 },
    { lng: -74.0447235107421, lat: 40.8086128234864 },
    { lng: -74.0449981689453, lat: 40.8083343505861 },
    { lng: -74.045555114746, lat: 40.8083343505861 },
    { lng: -74.0461120605469, lat: 40.8088874816895 },
    { lng: -74.04638671875, lat: 40.8086128234864 },
    { lng: -74.0466690063477, lat: 40.8086128234864 },
    { lng: -74.0469436645508, lat: 40.8083343505861 },
    { lng: -74.0472259521484, lat: 40.8083343505861 },
    { lng: -74.0475006103516, lat: 40.8086128234864 },
    { lng: -74.0480575561523, lat: 40.8086128234864 },
    { lng: -74.0483322143554, lat: 40.8083343505861 },
    { lng: -74.0491638183594, lat: 40.8083343505861 },
    { lng: -74.049446105957, lat: 40.8080558776857 },
    { lng: -74.0500030517578, lat: 40.8080558776857 },
    { lng: -74.0502777099609, lat: 40.8077774047853 },
    { lng: -74.0522232055663, lat: 40.8077774047853 },
    { lng: -74.0524978637695, lat: 40.8080558776857 },
    { lng: -74.053337097168, lat: 40.8080558776857 },
    { lng: -74.0536117553711, lat: 40.8077774047853 },
    { lng: -74.0558319091797, lat: 40.8077774047853 },
    { lng: -74.0561141967773, lat: 40.8074989318849 },
    { lng: -74.0566635131836, lat: 40.8074989318849 },
    { lng: -74.0569458007812, lat: 40.8072204589845 },
    { lng: -74.0572204589843, lat: 40.8072204589845 },
    { lng: -74.0575027465819, lat: 40.8069458007813 },
    { lng: -74.0591659545898, lat: 40.8069458007813 },
    { lng: -74.0594482421875, lat: 40.8066673278809 },
    { lng: -74.0605545043944, lat: 40.8066673278809 },
    { lng: -74.0608367919921, lat: 40.8069458007813 },
    { lng: -74.0613861083984, lat: 40.8069458007813 },
    { lng: -74.061668395996, lat: 40.8066673278809 },
    { lng: -74.0625, lat: 40.8066673278809 },
    { lng: -74.0630569458008, lat: 40.8061103820801 },
    { lng: -74.0633316040039, lat: 40.8061103820801 },
    { lng: -74.0636138916016, lat: 40.8058319091797 },
    { lng: -74.0638885498046, lat: 40.8058319091797 },
    { lng: -74.0644454956054, lat: 40.8052787780762 },
    { lng: -74.0647201538085, lat: 40.8052787780762 },
    { lng: -74.0655517578125, lat: 40.8044433593751 },
    { lng: -74.0666961669922, lat: 40.8040237426758 },
    { lng: -74.0683364868164, lat: 40.8025016784669 },
    { lng: -74.0687713623047, lat: 40.8020515441895 },
    { lng: -74.0691680908203, lat: 40.8016662597657 },
    { lng: -74.0697250366211, lat: 40.801109313965 },
    { lng: -74.0697250366211, lat: 40.8008346557618 },
    { lng: -74.0699996948241, lat: 40.8005561828614 },
    { lng: -74.0699996948241, lat: 40.800277709961 },
    { lng: -74.0694427490234, lat: 40.7997207641602 },
    { lng: -74.0702743530273, lat: 40.7988891601562 },
    { lng: -74.0702743530273, lat: 40.7986106872559 },
    { lng: -74.0713882446289, lat: 40.7986106872559 },
    { lng: -74.071662902832, lat: 40.7983322143555 },
    { lng: -74.0722198486328, lat: 40.7983322143555 },
    { lng: -74.0725021362305, lat: 40.7986106872559 },
    { lng: -74.0733337402343, lat: 40.7986106872559 },
    { lng: -74.0738906860351, lat: 40.7980537414551 },
    { lng: -74.0741653442382, lat: 40.7980537414551 },
    { lng: -74.0744476318359, lat: 40.7983322143555 },
    { lng: -74.0747222900391, lat: 40.7983322143555 },
    { lng: -74.0749969482422, lat: 40.7986106872559 },
    { lng: -74.0761108398438, lat: 40.7975006103515 },
    { lng: -74.0763854980468, lat: 40.7975006103515 },
    { lng: -74.0774993896484, lat: 40.7969436645508 },
    { lng: -74.0777740478516, lat: 40.7969436645508 },
    { lng: -74.0783309936523, lat: 40.796112060547 },
    { lng: -74.0788879394531, lat: 40.7955551147462 },
    { lng: -74.0794448852539, lat: 40.7955551147462 },
    { lng: -74.0805587768554, lat: 40.7944450378419 },
    { lng: -74.0805587768554, lat: 40.7938880920411 },
    { lng: -74.0808334350585, lat: 40.7936096191407 },
    { lng: -74.0808334350585, lat: 40.7933349609375 },
    { lng: -74.0811080932617, lat: 40.7930564880371 },
    { lng: -74.0811080932617, lat: 40.7927780151367 },
    { lng: -74.0813903808594, lat: 40.7924995422363 },
    { lng: -74.0813903808594, lat: 40.7919425964355 },
    { lng: -74.0819473266602, lat: 40.7913894653321 },
    { lng: -74.0819473266602, lat: 40.7900009155274 },
    { lng: -74.0824966430664, lat: 40.7894439697267 },
    { lng: -74.0824966430664, lat: 40.7888870239259 },
    { lng: -74.0832748413086, lat: 40.7879905700684 },
    { lng: -74.0836105346679, lat: 40.7880554199219 },
    { lng: -74.0841674804688, lat: 40.7874984741212 },
    { lng: -74.0844421386719, lat: 40.7874984741212 },
    { lng: -74.0847244262695, lat: 40.787223815918 },
    { lng: -74.0847244262695, lat: 40.7869453430176 },
    { lng: -74.0849990844727, lat: 40.7866668701172 },
    { lng: -74.0850448608398, lat: 40.786434173584 },
    { lng: -74.0875244140624, lat: 40.7841911315919 },
];
