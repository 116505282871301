import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { tSelect } from 'src/types';
import { getCommingSoon } from 'src/store';

import './SelectMain.scss';

export const SelectMain = ({ open, menu }: { open: boolean; menu: tSelect }) => {
    const dispatch = useDispatch();

    return (
        <div className={`main-select-dropdown main-select-dropdown--${open ? 'open' : 'close'}`}>
            <ul className="main-select-dropdown-list">
                {menu.map(el => {
                    if (el.path) {
                        return (
                            <NavLink
                                key={el.title}
                                to={el.path}
                                className="main-select-dropdown-list-item"
                            >
                                {el.title}
                            </NavLink>
                        );
                    } else
                        return (
                            <li
                                className="main-select-dropdown-list-item"
                                key={el.title}
                                onClick={() => dispatch(getCommingSoon(true))}
                            >
                                {el.title}
                            </li>
                        );
                })}
            </ul>
        </div>
    );
};
