export const initialState = {
    query: {
        address: '',
        owner_name: '',
        block: '',
        lot: '',
        qualifier: '',
        tax: '',
    },
    houses: [],
    has_more: false,
    count: 0,
};
