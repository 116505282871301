import './NotFound.scss';

export const NotFound = () => {
    return (
        <div className="not-found">
            <div className="not-found__content">
                <h1>404</h1>
                <h3>PAGE NOT FOUND</h3>
            </div>
        </div>
    );
};
