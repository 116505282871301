export default {
    control: {
        fontSize: 14,
        fontWeight: 'normal',
    },

    '&multiLine': {
        control: {
            minHeight: 63,
        },
        highlighter: {
            borderWidth: 0,
        },
    },

    suggestions: {
        list: {
            backgroundColor: 'white',
            border: '1px solid #6a5ecc73',
            borderBottomWidth: 0,
            boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.02)',
            fontSize: 13,
            maxHeight: '101px',
            overflowY: 'auto',
        },
        item: {
            padding: '5px 10px',
            borderBottom: '1px solid #6a5ecc73',
            color: '#1e1231',
            '&focused': {
                color: '#6a5ecc',
                backgroundColor: '#fbfaff',
            },
        },
    },
};
