import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Table } from 'src/components';
import { tHouseSmall } from 'src/types';

import { HOUSES_COLUMNS } from 'src/mockData/tables';
import { TableRow } from './TableRow';

interface IProps {
    municipality_slug?: string;
    items: tHouseSmall[];
}

export const ListView: FC<IProps> = ({ municipality_slug, items }) => {
    const navigate = useNavigate();
    return (
        <div>
            <Table collums={HOUSES_COLUMNS}>
                {items?.map(row => {
                    return (
                        <TableRow
                            {...row}
                            key={`row-${row.id}`}
                            className="pointer"
                            onClick={() =>
                                navigate(
                                    `/municipality/${
                                        municipality_slug
                                            ? municipality_slug
                                            : row.municipality.slug
                                    }/${row.slug}`
                                )
                            }
                        />
                    );
                })}
            </Table>
        </div>
    );
};
