import { Dispatch } from 'redux';
import { apiClient } from 'src/api';
import { GET_MUNICIPALITIES, GET_MUNICIPALITY } from './action-types';
import { tMunicipalitiesAction, tMunicipalityAction } from './types';

export const changeMunicipalitiesData = (data: tMunicipalitiesAction) => {
    return {
        type: GET_MUNICIPALITIES,
        payload: {
            data,
        },
    };
};

export const changeMunicipalityData = (data: tMunicipalityAction) => {
    return {
        type: GET_MUNICIPALITY,
        payload: {
            data,
        },
    };
};

export const getMunicipalitiesAsync = () => async (dispatch: Dispatch) => {
    try {
        const { data } = await apiClient.get(`/municipalities`);
        dispatch(changeMunicipalitiesData(data.response));
    } catch (e) {
        console.log(e);
    }
};

export const getMunicipalityAsync = (slug: string) => async (dispatch: Dispatch) => {
    try {
        const { data } = await apiClient.get(`/municipality?municipality_slug=${slug}`);
        dispatch(changeMunicipalityData(data.response));
    } catch (e) {
        console.log(e);
    }
};
