import { Comment } from './Comment';
import { WriteComment } from './WriteComment';

import './Comments.scss';
import { tComment } from 'src/types';
import { useAppSelector } from 'src/hooks';

export const Comments = ({
    comments,
    onSave,
}: {
    comments: tComment[];
    onSave: (val: string) => void;
}) => {
    const { user } = useAppSelector(state => state.userReducer);

    return (
        <div className="comments">
            <div className="container comments__content">
                <h2>Comments</h2>
                {comments?.length > 0 &&
                    comments.map(el => {
                        return (
                            <div key={`${el.full_name}-${el.created_at}`} className="comments__box">
                                <Comment data={el} />
                            </div>
                        );
                    })}
                <div className="comments__box">
                    <WriteComment user={user} onSave={onSave} />
                </div>
            </div>
        </div>
    );
};
