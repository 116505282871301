import { Dispatch } from 'redux';
import { apiClient } from 'src/api';
import { GET_HOUSE } from './action-types';
import { tHouseAction } from './types';

export const changeHouseData = (data: tHouseAction) => {
    return {
        type: GET_HOUSE,
        payload: {
            data,
        },
    };
};

export const getHouseAsync = (slug: string) => async (dispatch: Dispatch) => {
    try {
        const { data } = await apiClient.get(`/estate/${slug}`);
        dispatch(changeHouseData(data.response));
    } catch (e) {
        console.log(e);
    }
};

export const setHouseDocs =
    ({ formdata, slug }: { formdata: FormData; slug: string }) =>
    async (dispatch: Dispatch) => {
        try {
            const { data } = await apiClient.post(`/estate/${slug}/upload-attachments`, formdata);
            dispatch(changeHouseData(data.response));
        } catch (e) {
            console.log(e);
        }
    };

export const setComment =
    ({ comment, slug }: { comment: { user_id: string; text: string }; slug: string }) =>
    async (dispatch: Dispatch) => {
        try {
            const { data } = await apiClient.post(`/estate/${slug}/comment`, comment);
            return data;
        } catch (e) {
            console.log(e);
        }
    };
