import { copyText } from 'src/helpers';
import './InfoCard.scss';
import { FC, ReactNode } from 'react';

interface IProps {
    icon?: ReactNode;
    title: string;
    text: string | null;
    reversed?: boolean;
    copy?: string;
}

export const InfoCard: FC<IProps> = ({ title, text, icon, reversed, copy }) => {
    return (
        <div className="info-card">
            {icon && <div className="info-card__icon">{icon}</div>}
            <div
                className="info-card__content"
                style={{ flexDirection: reversed ? 'column-reverse' : 'column' }}
            >
                <h4>{title}</h4>
                <h3 className={copy && 'clickable'} onClick={() => copy && copyText(copy)}>
                    {text || '-'}
                </h3>
            </div>
        </div>
    );
};
